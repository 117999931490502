<template>
  <div
    class="min-h-screen flex flex-col justify-between bg-muted"
  >
    <div>
      <div
        container="padded"
        grid="~ cols-[1fr_1fr]"
        class="z-10 flex flex-grow justify-between gap-4 bg-base py-2.5"
        border="b base"
      >
        <AppLogo />
        <div class="flex gap-x-4">
          <GThemeSwitcher />
          <SettingsColorMode />
        </div>
      </div>
      <main>
        <slot />
      </main>
    </div>

    <LazyAppFooter />
  </div>
</template>
